import React from 'react';
import styled from 'styled-components';
import logo from '../../resources/icons/icon-15.svg';
import { Grid } from '@mui/material';
import { SlideAbout } from './slides/SlideAbout';
import { TabletServices } from './Tablet/TabletServices';
import { Footer } from '../Footer';
import { Contact } from '../Contact';
import { SlideContent } from './parts/SlideContent';
import { Specializations } from '../Specializations/Specializations';
import {SectionHeaderMobile} from "../../components/Text";

export const LandingPageTablet = () => {
    return (
        <Container>
            <Hello>
                <HelloImage src={logo} />
            </Hello>
            <Grid container alignItems="center" justifyContent="center">
                <SlideAbout />
            </Grid>
            <Grid container>
                <TabletServices />
            </Grid>
            <SlideContent>
                <SpecContainer>
                    <Specializations />
                </SpecContainer>
            </SlideContent>
            <ContactWrapper>
                <Contact />
            </ContactWrapper>
            <Footer />
        </Container>
    );
};

const Container = styled.div``;

const Hello = styled.div`
    height: 100vh;
    max-height: -webkit-fill-available;
    background: white;

    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    padding-top: 85px;

    background-image: url(resources/images/wesjcie-kom.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const HelloImage = styled.img`
    width: 80%;
`;

const ContactWrapper = styled.div`
    ${(props) => props.theme.breakpoints.down('md')} {
        padding: 0;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding-left: 0;
        padding-right: 0;
        text-align: left;
    }
`;

const SpecContainer = styled.div`
    margin-bottom: 50px;
    
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        & ${SectionHeaderMobile} {
            width: 65%;
            margin: 0 auto 30px; 
        }   
    }
`;
