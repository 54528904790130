import React from 'react';
import './App.css';
import { LandingPage } from './modules/LandingPage/LandingPage';
import { Nav } from './modules/Nav/Nav';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PageAbout } from './modules/PageAbout';
import { PageServices } from './modules/PageServices';
import { PageSpecializations } from './modules/PageSpecializations';
import { PageContact } from './modules/PageContact';
import { PageServiceRepresentation } from './modules/PageServiceRepresentation';
import { PageServiceAdvices } from './modules/PageServiceAdvices';
import { PageServiceCreatingOpinions } from './modules/PageServiceCreatingOpinions';
import { PageServiceCreatingContracts } from './modules/PageServiceCreatingContracts';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

function changeFavIcon() {
    const link = document.querySelector('link[rel="icon"]');

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // @ts-ignore
        // link.href = '/resources/logos/100-white.svg';
        link.href = '/resources/logos/ico_white.png';
    } else {
        // @ts-ignore
        // link.href = '/resources/logos/100-blue.svg';
        link.href = '/resources/logos/ico_blue.png';
    }
}

function App() {
    const [activeSlide, setActiveSlide] = React.useState(0);

    const location = useLocation();

    React.useEffect(() => {
        const mql = window.matchMedia('(prefers-color-scheme: dark)');
        mql.addEventListener('change', changeFavIcon);

        changeFavIcon();
    }, [])

    return (
        <div>
            <Nav logoVisible={activeSlide > 0} textVisible={activeSlide > 0} activeSlide={activeSlide} />

            <TransitionGroup component={null}>
                <CSSTransition key={location.key} classNames="fade" timeout={1000}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <LandingPage
                                    activeSlide={activeSlide}
                                    onSlideChange={(state) => setActiveSlide(state)}
                                />
                            }
                        />
                        <Route
                            path="*"
                            element={
                                <LandingPage
                                    activeSlide={activeSlide}
                                    onSlideChange={(state) => setActiveSlide(state)}
                                />
                            }
                        />
                        <Route path="/o-kancelarii" element={<PageAbout />} />
                        <Route path="/zakres-uslug" element={<PageServices />} />
                        <Route path="/zakres-uslug/udzielanie-porad-prawnych" element={<PageServiceAdvices />} />
                        <Route
                            path="/zakres-uslug/sporzadzanie-opinii-prawnych"
                            element={<PageServiceCreatingOpinions />}
                        />
                        <Route
                            path="/zakres-uslug/tworzenie-i-opiniowanie-umow"
                            element={<PageServiceCreatingContracts />}
                        />
                        <Route path="/zakres-uslug/zastepstwo-procesowe" element={<PageServiceRepresentation />} />
                        <Route path="/specjalizacje" element={<PageSpecializations />} />
                        <Route path="/kontakt" element={<PageContact />} />
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

export default App;
