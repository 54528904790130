import React, {useState} from "react";
import {APIProvider, InfoWindow, Map, Marker, useMarkerRef} from "@vis.gl/react-google-maps";
import styled from "styled-components";

interface IProps {
    zoom?: number;
    disableDefaultUI?: boolean;
    gestureHandling?: 'cooperative' | 'greedy' | 'none' | 'auto';
}

export const ContactGoogleMap = ({
     zoom = 18,
     disableDefaultUI = false,
     gestureHandling = 'auto'
 }: IProps) => {
    const mapAndMarkerPosition = {lat: 51.7454179, lng: 19.8153067}

    const [infoOpen, setInfoOpen] = useState(false);

    const [markerRef, marker] = useMarkerRef();

    return (
        <APIProvider apiKey={"AIzaSyDU5iRbqFcbUpgE9ZZTv6FBLN5u7PNdsaI"}>
            <Map
                zoom={zoom}
                center={mapAndMarkerPosition}
                gestureHandling={gestureHandling}
                disableDefaultUI={disableDefaultUI}
                streetViewControl={false}
                styles={[{
                    'featureType': 'landscape',
                    'stylers': [{'saturation': -100}, {'lightness': 65}, {'visibility': 'on'}]
                }, {
                    'featureType': 'poi',
                    'stylers': [{'saturation': -100}, {'lightness': 51}, {'visibility': 'off'}]
                }, {
                    'featureType': 'road.highway',
                    'stylers': [{'saturation': -100}, {'visibility': 'simplified'}]
                }, {
                    'featureType': 'road.arterial',
                    'stylers': [{'saturation': -100}, {'lightness': 30}, {'visibility': 'on'}]
                }, {
                    'featureType': 'road.local',
                    'stylers': [{'saturation': -100}, {'lightness': 40}, {'visibility': 'on'}]
                }, {
                    'featureType': 'transit',
                    'stylers': [{'saturation': -100}, {'visibility': 'simplified'}]
                }, {
                    'featureType': 'administrative.province',
                    'stylers': [{'visibility': 'off'}]
                }, {
                    'featureType': 'water',
                    'elementType': 'labels',
                    'stylers': [{'visibility': 'on'}, {'lightness': -25}, {'saturation': -100}]
                }, {
                    'featureType': 'water',
                    'elementType': 'geometry',
                    'stylers': [{'hue': '#ffff00'}, {'lightness': -25}, {'saturation': -97}]
                }]}
            >
                <Marker
                    position={mapAndMarkerPosition}
                    visible
                    ref={markerRef}
                    onClick={() => setInfoOpen(prev => !prev)}
                    icon={'/resources/icons/icon-14-map-50.png'}
                    // @ts-ignore

                />

                {infoOpen ? (
                    <InfoWindow anchor={marker} onCloseClick={() => setInfoOpen(false)}>
                        <div style={{
                            textAlign: 'left'
                        }}>
                            <InfoWrapperText><b>Kancelaria Radcy Prawnego</b></InfoWrapperText>
                            <InfoWrapperText>Michał Olczyk</InfoWrapperText>
                            <InfoWrapperText>ul. Brzezińska 14</InfoWrapperText>
                            <InfoWrapperText>95-040 Koluszki</InfoWrapperText>
                            <InfoWrapperText>
                                <a target={'_blank'}
                                   href={'https://www.google.pl/maps/place/Kancelaria+Radcy+Prawnego+Micha%C5%82+Olczyk/@51.7454179,19.8127318,17z/data=!3m1!4b1!4m6!3m5!1s0x471bd5391c507c4d:0x7dbe2925d4bd768c!8m2!3d51.7454179!4d19.8153067!16s%2Fg%2F11rzpzdn9q?entry=ttu'}>
                                    Wyznacz trasę
                                </a>
                            </InfoWrapperText>
                        </div>
                    </InfoWindow>
                ) : null}
            </Map>
        </APIProvider>
    )
}

export const InfoWrapperText = styled.p`
    padding-bottom: 2px;
    font-size: 13px;
    color: #083e71;
    
    &:first-child {
        padding-top: 8px;
    }
`