import React from 'react';
import styled from 'styled-components';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { KnowMore, Text } from '../components/Text';
import { Link } from 'react-router-dom';
import { Footer } from './Footer';

export enum Service {
    Advices = 1,
    CreatingContracts,
    CreatingOpinions,
    Representation,
}

interface IService {
    name: Service;
    text: string;
    link: string;
    bg: string;
    swiperBg: string;
}

export const services: IService[] = [
    {
        name: Service.Advices,
        text: 'Udzielanie porad prawnych',
        link: '/zakres-uslug/udzielanie-porad-prawnych',
        bg: '/resources/images/MENU_zakres_spraw_udzielanie_porad_prawnych.jpg',
        swiperBg: '/resources/images/KWADRAT_udzielanie_porad_prawnych.jpg',
    },
    {
        name: Service.CreatingOpinions,
        text: 'Sporządzanie opinii prawnych',
        link: '/zakres-uslug/sporzadzanie-opinii-prawnych',
        bg: '/resources/images/MENU_sporzadznie_opinii_prawnych.jpg',
        swiperBg: '/resources/images/KWADRAT_pozostale_uslugi_sporzadzanie.jpg',
    },
    {
        name: Service.CreatingContracts,
        text: 'Tworzenie i opiniowanie umów',
        link: '/zakres-uslug/tworzenie-i-opiniowanie-umow',
        bg: '/resources/images/MENU_tworzenie_opiniowanie_umow.jpg',
        swiperBg: '/resources/images/KWADRAT_tworzenie_umow.jpg',
    },
    {
        name: Service.Representation,
        text: 'Zastępstwo procesowe i polubowne rozstrzyganie sporów',
        link: '/zakres-uslug/zastepstwo-procesowe',
        bg: '/resources/images/MENU_zastepstwo_rozstrzyganie_sporod.jpg',
        swiperBg: '/resources/images/KWADRAT_zastepstwo_prcesowe.jpg',
    },
];

export const PageServices = () => {
    const theme = useTheme();
    const devicePhone = useMediaQuery(theme.breakpoints.down('sm'));
    const [isTop, setIsTop] = React.useState(true);

    React.useEffect(() => {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }, [])

    React.useEffect(() => {
        window.addEventListener('scroll', listenToScroll);

        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
    }, []);

    function handleLinkChange() {
        window.scroll(0, 0);
    }

    function listenToScroll() {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

        if (scrollTop > 30) {
            setIsTop(false);
        } else {
            setIsTop(true);
        }
    }

    function renderService({ bg, text }: IService) {
        if (devicePhone) {
            return (
                <TextContainerPhone>
                    <PhoneImage bg={bg} />
                    <Grid container justifyContent="center" alignItems="center">
                        <ServiceTextMobile>{text}</ServiceTextMobile>
                        {/*<ArrowContainer>*/}
                        {/*    <Arrow />*/}
                        {/*</ArrowContainer>*/}
                    </Grid>
                </TextContainerPhone>
            );
        }

        return (
            <TextContainer bg={bg}>
                <ServiceText>{text}</ServiceText>
                <ServiceKnowMore>Dowiedz się więcej</ServiceKnowMore>
            </TextContainer>
        );
    }

    return (
        <Container>

            <Grid container>
                {services.map((service: IService) => (
                    <Grid key={service.name} item xs={12} sm={12} md={12}>
                        <Link to={service.link} onClick={handleLinkChange}>{renderService(service)}</Link>
                    </Grid>
                ))}
            </Grid>

            <NextSlideWrapper visible={isTop}>
                    <NextSlideTriangle />
            </NextSlideWrapper>

            <Footer />
        </Container>
    );
};

const Container = styled.div`
    margin-top: 140px;
`;

const ServiceTextMobile = styled(Text)`
    text-align: center;
`

const ServiceText = styled(Text)`
    ${(props) => props.theme.breakpoints.down('sm')} {
        font-size: '25px';
    }

    padding: 10px 100px;

    font-size: 30px;
    color: #eaf5f8;
    cursor: pointer;

    z-index: 1;
    transition: color 0.3s ease-in;

    width: 100%;
    position: relative;

    float: right;
    z-index: 1;
    text-align: center;

    &:before {
        position: absolute;
        top: -20px;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        padding: 30px 0;
        background: #eff7fa;
        float: left;
        z-index: -1;
        opacity: 0.4;
    }
`;

const ServiceKnowMore = styled(KnowMore)`
    transition: all 0.3s ease-in;
    z-index: 1;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 0;
        width: 0;
        border-style: solid;
        border-width: 3px 0 3px 4px;
        right: -10px;
        transition: all 0.3s ease-in;
        border-color: transparent transparent transparent white;
        // border-color: transparent transparent transparent red;
        z-index: 1;
    }
`;

interface ITextContainerProps {
    bg: string;
}

const TextContainer = styled.div<ITextContainerProps>`
    width: 100%;
    height: 380px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('${({ bg }: ITextContainerProps) => bg}');
         background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        transition: all 0.3s ease-in;
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        &::before {
            opacity: 1;
        }

        & ${ServiceText}, & ${ServiceKnowMore} {
            color: #fff;

            &::after {
                border-color: transparent transparent transparent #eff7fa;
                // color: #eff7fa !important;
            }
        }

        & ${ServiceText}:before {
            background-color: #083e71;
            opacity: 0.4;
        }
    }

    // dla duzych rozdzialek dodajemy hover
    ${(props) => props.theme.breakpoints.up('lg')} {
        &:before {
            opacity: 0;
        }

        & ${Text} {
            color: #083e71;
        }

        &:hover {
            &::before {
                opacity: 1;
            }

            & ${ServiceText}, & ${ServiceKnowMore} {
                color: #fff;

                &::after {
                    border-color: transparent transparent transparent #eff7fa;
                    // color: #eff7fa !important;
                }
            }

            & ${ServiceText}:before {
                background-color: #083e71;
                opacity: 0.3;
            }
        }
    }
`;

// phone
const TextContainerPhone = styled.div`
    width: 100%;
    padding: 20px;
`;

const PhoneImage = styled.div<{ bg: string }>`
    max-width: 100%;
    height: 200px;
    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-position: top center;
`;

const ArrowContainer = styled.div`
    position: relative;
`;

const Arrow = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent #eff7fa;
    // border-color: transparent transparent transparent red;
`;

export const NextSlideWrapper = styled.div<{ visible: boolean}>`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    z-index: 2;
    
    transition: all 0.3s linear;
    opacity: ${({ visible }) => visible ? '1' : '0'};
`

export const NextSlideTriangle = styled.div`
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 8px 0 8px;
    border-color: #083e71 transparent transparent transparent;
    
    z-index: 1000;
    
    -webkit-animation: scale-down-bottom-big 1s ease-in-out infinite alternate both;
    animation: scale-down-bottom-big 1s ease-in-out infinite alternate both;
`