import React from 'react';
import './LandingPage.css';
import { LandingPageDesktop } from './LandingPageDesktop';
import { useMediaQuery, useTheme } from '@mui/material';
import { LandingPageTablet } from './LandingPageTablet';

interface ILandingPageProps {
    activeSlide: number;
    onSlideChange(state: number): void;
}

export function LandingPage(props: ILandingPageProps) {
    const theme = useTheme();
    const deviceTablet = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(() => {
        props.onSlideChange(0);
    }, []);

    return (
        <div className={`landing-page-wrapper `}>
            <div
                className={
                    deviceTablet
                        ? ''
                        : `landing-page-wrapper__static-background ${
                              props.activeSlide === 1 ? 'landing-page-wrapper__static-background--active' : ''
                          }`
                }
            />

            {deviceTablet ? (
                <LandingPageTablet />
            ) : (
                <LandingPageDesktop activeSlide={props.activeSlide} onSlideChange={props.onSlideChange} />
            )}
        </div>
    );
}
