import React from 'react';
import { SubPageTheme } from '../components/SubPageTheme';
import { SubPageServiceTheme } from './SubPageServiceTheme';
import { Text } from '../components/Text';
import { Service } from './PageServices';

export const PageServiceCreatingOpinions = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_Zakres_uslug_sporzadzanie_opinii_prawnych.jpg">
            <SubPageServiceTheme header="Sporządzanie opinii prawnych" activeService={Service.CreatingOpinions}>
                <Text>
                    To materiał analityczny odnoszący się do konkretnego zagadnienia prawnego. W opinii prawnej
                    znajdziemy opis problemu, analizę sprawy z uwzględnieniem aktualnych przepisów prawa, określenie
                    ewentualnych zagrożeń, potencjalnych kosztów i wnioski końcowe. Z opinii prawnych korzystają zarówno
                    przedsiębiorcy, jak i osoby prywatne, gdy potrzebują profesjonalnego oglądu i wskazania korzystnych
                    wyjść z danej sytuacji. Taka obiektywna opinia wystawiana przez specjalistę w dziedzinie prawa
                    pozwala spojrzeć na problem z nowej perspektywy i znaleźć najlepsze rozwiązanie.
                </Text>
            </SubPageServiceTheme>
        </SubPageTheme>
    );
};
