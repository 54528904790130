import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Information } from '../../components/Text';
import { imagesMap, specializationsDesktop, Specs, texts } from './constants';

export const SpecializationsDesktop = () => {
    const [active, setActive] = React.useState(Specs.SporySadowe);

    const handleChangeSpec = (spec: Specs) => () => {
        setActive(spec);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12}>
                <Grid container justifyContent="center" alignItems="center">
                    {specializationsDesktop.map((spec) => (
                        <Icon
                            key={spec}
                            active={active === spec}
                            onClick={handleChangeSpec(spec)}
                            onMouseEnter={handleChangeSpec(spec)}
                            index={spec}
                        />
                    ))}
                </Grid>
            </Grid>
            <Texts item md={8} justifyContent="center" textAlign="center" className="landing-page-specs__texts">
                {specializationsDesktop.map((spec) => (
                    <Text active={active === spec}>{texts[spec]}</Text>
                ))}
            </Texts>
        </Grid>
    );
};

function Icon(props: any) {
    return (
        <ImageContainer {...props}>
            {/* @ts-ignore*/}
            <Image src={imagesMap[props.index]} />
        </ImageContainer>
    );
}

function Text(props: any) {
    return (
        <TextContainer visible={props.active}>
            <Information>{props.children}</Information>
        </TextContainer>
    );
}

interface ITextContainerProps {
    visible: boolean;
}

const Texts = styled(Grid)`
    ${props => props.theme.breakpoints.up('xl')} {
        height: 230px;
    }
`

const TextContainer = styled.div`
    position: absolute;
    top: 50px;
    left: 0;

    line-height: 35px;

    transition: all 0.5s ease-in;
    transition-delay: ${({ visible }: ITextContainerProps) => (visible ? '.5s' : '0')};
    opacity: ${({ visible }: ITextContainerProps) => (visible ? '1' : '0')};

    ${(props) => props.theme.breakpoints.up('xl')} {
        top: 50px;
        width: 70%;
        left: 50%;
        transform: translateX(-50%);
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        top: 30px;
    }
`;

const ImageContainer = styled.div<{ active?: boolean }>`
    width: 130px;
    height: 130px;
    margin-right: -30px;

    display: inline-block;

    cursor: pointer;
    transition: all 0.2s ease-in;
    transform: rotate(45deg) ${(props) => (props.active ? 'scale(1.1)' : '')};

    opacity: ${(props) => (props.active ? '1' : '.7')};

    &:nth-child(even) {
        margin-top: 190px;
    }

    &:hover {
        transform: scale(1.1) rotate(45deg);
    }

    & img {
        transform: rotate(-45deg);
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        width: 115px;
        height: 115px;
    }
`;

const Image = styled.img`
    max-width: 100%;
`;
