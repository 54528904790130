import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { PageServiceOthers } from './PageServiceOthers';
import { Service } from './PageServices';
import { Text } from '../components/Text';

interface ISubPageServiceThemeProps {
    children: React.ReactNode;
    header: string;
    activeService?: Service;
}

export const SubPageServiceTheme = ({ children, header, activeService }: ISubPageServiceThemeProps) => {
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={10}>
                        <Header>{header}</Header>
                    </Grid>

                    <Grid item xs={9} md={12} xl={6}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>

            {activeService ? (
                <OtherServicesContainer item xs={12}>
                    <PageServiceOthers activeService={activeService} />
                </OtherServicesContainer>
            ) : null}
        </Grid>
    );
};

export const Header = styled(Text)`
    font-size: 41px;
    padding-bottom: 20px;
    color: #083e71;
`;

const OtherServicesContainer = styled(Grid)`
    padding-top: 70px;
`;
