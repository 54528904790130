import React from 'react';
import { services } from '../parts/Services';
import { SectionHeader, SectionHeaderMobile, Text, TextLink } from '../../../components/Text';
import { SlideContent } from '../parts/SlideContent';
import { Links } from '../../../constants/links';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export const TabletServices = () => {
    return (
        <Container>
            <SlideContent container>
                <Grid item xs={12}>
                    <SectionHeaderMobile>ZAKRES USŁUG</SectionHeaderMobile>
                </Grid>
                {services.map((service) => (
                    <Service key={service.text} link={service.link} text={service.text} />
                ))}
            </SlideContent>
        </Container>
    );
};

interface IServiceProps {
    link: Links;
    text: string;
}

const Service = ({ link, text }: IServiceProps) => {
    return (
        <Grid item xs={12}>
            <ServiceContainer container justifyContent="space-between" alignItems="center">
                <TextLink link={link}>{text}</TextLink>
                <Arrow />
            </ServiceContainer>
        </Grid>
    );
};

const ServiceContainer = styled(Grid)`
    ${Text} {
        flex: 1;
    }
`;

const Container = styled.div`
    padding: 50px 0;
    
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        & ${SectionHeaderMobile} {
            width: 65%;
            margin: 0 auto 30px; 
        }   
    }
`;

const Arrow = styled.span`
    width: 7px;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent #6c7f96;
    // border-color: transparent transparent transparent red;
`;
