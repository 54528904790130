import React from 'react';
import { Grid } from '@mui/material';
import { SectionHeaderMobile } from '../../components/Text';
import { Specs } from './constants';

export const SpecializationsMediaTheme: React.FC = ({ children }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <SectionHeaderMobile>SPECJALIZACJE</SectionHeaderMobile>
            </Grid>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
};
