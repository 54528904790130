import React from 'react';
import { Grid } from '@mui/material';
import { Paragraph } from '../components/Paragraph/Paragraph';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Links } from '../constants/links';
import { FooterLink, FooterParagraph, InformationSecondaryLink } from '../components/Text';

export const Footer = () => {
    return (
        <Container>
            <FooterContentTop container>
                <Grid item xs={12} md={12} textAlign="center">
                    <LogoContainer container justifyContent="center">
                        <LogoImg className="landing-page-contact__logo" src="/resources/icons/icon-10.svg" />
                    </LogoContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                    <MenuItems container justifyContent="center">
                        <MenuItem link={Links.About}>O KANCELARII</MenuItem>
                        <MenuItem link={Links.ZakresUslug}>ZAKRES USŁUG</MenuItem>
                        <MenuItem link={Links.Specializacje}>SPECJALIZACJE</MenuItem>
                        <MenuItem link={Links.Kontakt}>KONTAKT</MenuItem>
                    </MenuItems>
                </Grid>
            </FooterContentTop>
            <Grid container alignItems="center" direction="column">
                <SocialMediaContainer>
                    <a href="https://www.facebook.com/KancelariaOlczyk" target="_blank">
                        <SocialMediaIcon src="/resources/icons/icon-11.svg" alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/kancelaria_olczyk/" target="_blank">
                        <SocialMediaIconInsta src="/resources/icons/insta-white.svg" alt="Instagram - Kancelaria Olczyk" />
                    </a>
                    <a href="http://www.linkedin.com/in/kancelariaolczyk" target="_blank">
                        <SocialMediaIconLinked src="/resources/icons/linked-white.svg" alt="LinkedIn - Kancelaria Olczyk" />
                    </a>
                    <a href="https://www.tiktok.com/@kancelaria_olczyk" target="_blank">
                        <SocialMediaIconTiktok src="/resources/icons/tiktok-white.svg" alt="TikTok - Kancelaria Olczyk" />
                    </a>
                </SocialMediaContainer>
                <FooterParagraph>
                    WSZELKIE PRAWA ZASTRZEŻONE DLA KANCELARIA RADCY PRAWNEGO MICHAŁ OLCZYK
                </FooterParagraph>
                <FooterParagraph>
                    <span>
                        PROJEKT STRONY I IDENTYFIKACJI WIZUALNEJ:&nbsp;
                    </span>
                    <LinkToOligrafika href="https://www.oligrafika.pl">
                        OLIGRAFIKA
                    </LinkToOligrafika>
                </FooterParagraph>
            </Grid>
        </Container>
    );
};

interface IMenuItemProps {
    children: React.ReactNode;
    link: string;
}

function MenuItem({ children, link }: IMenuItemProps) {
    return (
        <MenuItemContainer>
            <FooterLink link={link}>{children}</FooterLink>
        </MenuItemContainer>
    );
}

const LinkToOligrafika = styled.a`
    text-decoration: underline;
`

const MenuItems = styled(Grid)`
    padding: 10px;

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 20px 0 10px;
    }
`;

const MenuItemContainer = styled.div`
    display: inline-block;
    margin: 0 10px;

    & p a {
        transition: color 0.3s ease-in;
    }

    & p:hover a {
        color: #6c7f96;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Container = styled.div`
    width: 100%;
    background-color: #083e71;
    color: white;
    padding-bottom: 15px;

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding-bottom: 20px;
    }
`;

const FooterContentTop = styled(Grid)`
    border-top: 1px solid white;
    margin-top: -2px;
    background: #fff;
    margin-bottom: 5px;
`;

const LogoContainer = styled(Grid)`
    margin-bottom: -25px;
`;

const LogoImg = styled.img`
    max-width: 300px;
`;

const SocialMediaContainer = styled.div``;

export const SocialMediaIcon = styled.img`
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-bottom: 5px;
    transition: all 0.3s ease-in;

    &:hover {
        opacity: 0.8;
    }
`;

export const SocialMediaIconInsta = styled(SocialMediaIcon)`
    margin-bottom: 6px;
    margin-left: 0px;
    width: 25px;
    height: 25px;
`

export const SocialMediaIconLinked = styled(SocialMediaIcon)`
    margin-bottom: 6px;
    margin-left: 5px;
    width: 25px;
    height: 25px;
`

export const SocialMediaIconTiktok = styled(SocialMediaIcon)`
    margin-bottom: 6px;
    margin-left: 1px;
    width: 25px;
    height: 25px;
`