import { Links } from '../../../constants/links';

export const services = [
    {
        link: Links.UdzielaniePoradPrawnych,
        text: 'Udzielanie porad prawnych',
    },
    {
        link: Links.SporzadzanieOpiniPrawnych,
        text: 'Sporządzanie opinii prawnych',
    },
    {
        link: Links.TworzenieIOpiniowanieUmow,
        text: 'Tworzenie i opiniowanie umów',
    },
    {
        link: Links.ZastepstwoProcesowe,
        text: 'Zastępstwo procesowe i\u00a0polubowne\u00a0rozstrzyganie sporów',
    },
];
