import { Grid } from '@mui/material';
import React from 'react';
import { SpecializationsDesktop } from '../../Specializations/SpecializationsDesktop';
import styled from 'styled-components';
import { SectionInfo } from '../parts/SectionInfo';
import { FullHeight } from '../../../components/FullHeight';
import { SectionHeader } from '../../../components/Text';

export const SlideSpecializations = () => {
    return (
        <SpecializationContainer>
            <Container container justifyContent="center" alignItems="center">
                <Grid item md={12}>
                    <SpecializationsDesktop />
                </Grid>
            </Container>

            <SectionInfo>
                <SectionHeader>SPECJALIZACJE</SectionHeader>
            </SectionInfo>
        </SpecializationContainer>
    );
};

const SpecializationContainer = styled(FullHeight)`
    background: #fff;
    position: relative;
`;

const Container = styled(Grid)`
    // position: relative;
    // padding-top: 5%;
    // padding-bottom: 50px;
    z-index: 2;
    height: 100vh;
    max-height: -webkit-fill-available;
    
    

    ${(props) => props.theme.breakpoints.down('xl')} {
        & > div {
            margin-top: -35vh;
        }
    }

    ${(props) => props.theme.breakpoints.up('xl')} {
        padding-top: 0;
        align-items: center;
    }
`;
