import React from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';

export const SlideContent = styled(Grid)`
    padding-left: 200px;
    padding-right: 100px;

    ${(props) => props.theme.breakpoints.up('lg')} {
        padding-left: 30%;
        padding-right: 15%;
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        padding-left: 170px;
        padding-right: 50px;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        padding: 0 100px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }
`;
