export enum Specs {
    SporySadowe = 'spory-sadowe',
    SprawyBankowe = 'sprawy-bankowe',
    Odszkodowania = 'odszkodowania',
    PrawoPracy = 'prawo-pracy',
    Umowy = 'umowy',
    PrawoRodzinne = 'prawo-rodzinne',
    PrawoSpadkowe = 'prawo-spadkowe',
    PrawoKonsumenckie = 'prawo-konsumenckie',
    PostepowanieEgzekucyjne = 'postępowanie-egzekucyjne',
}

export const imagesMap = {
    [Specs.SporySadowe]: '/resources/icons/icon-01.png',
    [Specs.SprawyBankowe]: '/resources/icons/icon-02.png',
    [Specs.Odszkodowania]: '/resources/icons/icon-03.png',
    [Specs.PrawoPracy]: '/resources/icons/icon-04.png',
    [Specs.Umowy]: '/resources/icons/icon-05.png',
    [Specs.PrawoRodzinne]: '/resources/icons/icon-06.png',
    [Specs.PrawoSpadkowe]: '/resources/icons/icon-07.png',
    [Specs.PrawoKonsumenckie]: '/resources/icons/icon-08.png',
    [Specs.PostepowanieEgzekucyjne]: '/resources/icons/icon-09.png',
};
export const specializationsDesktop = [
    Specs.SporySadowe,
    Specs.PrawoRodzinne,
    // Specs.SprawyBankowe,
    Specs.PrawoSpadkowe,
    Specs.Odszkodowania,
    Specs.PrawoKonsumenckie,
    Specs.PrawoPracy,
    Specs.PostepowanieEgzekucyjne,
    Specs.Umowy,
];

export const specializationsPhoneOld = [
    Specs.SporySadowe,
    // Specs.SprawyBankowe,
    Specs.Odszkodowania,
    Specs.PrawoPracy,
    Specs.Umowy,
    Specs.PrawoRodzinne,
    Specs.PrawoSpadkowe,
    Specs.PrawoKonsumenckie,
    Specs.PostepowanieEgzekucyjne,
];

export const specializationsPhone = [
    // [Specs.SporySadowe, Specs.SprawyBankowe],
    // [Specs.Odszkodowania, Specs.PrawoPracy],
    // [Specs.Umowy, Specs.PrawoRodzinne],
    // [Specs.PrawoSpadkowe, Specs.PrawoKonsumenckie],
    // [Specs.PostepowanieEgzekucyjne],


    [Specs.SporySadowe, Specs.Odszkodowania],
    [Specs.PrawoPracy, Specs.Umowy],
    [Specs.PrawoRodzinne, Specs.PrawoSpadkowe],
    [Specs.PrawoKonsumenckie, Specs.PostepowanieEgzekucyjne],
];

export const specializationsTablet = [
    [Specs.SporySadowe, Specs.Odszkodowania, Specs.PrawoPracy],
    [Specs.Umowy, Specs.PrawoRodzinne, Specs.PrawoSpadkowe],
    [Specs.PrawoKonsumenckie, Specs.PostepowanieEgzekucyjne],

    // [Specs.SporySadowe, Specs.SprawyBankowe, Specs.Odszkodowania],
    // [Specs.PrawoPracy, Specs.Umowy, Specs.PrawoRodzinne],
    // [Specs.PrawoSpadkowe, Specs.PrawoKonsumenckie, Specs.PostepowanieEgzekucyjne],
];

export const texts = {
    [Specs.SporySadowe]:
        'Praktyka sporów sądowych obejmuje wszystkie sprawy klientów (cywilne, gospodarcze, administracyjne), które swój finał znajdują w sądzie. Na każdym etapie sprawy staram się prowadzić spór do  porozumienia (ugody), a wszędzie tam, gdzie nie jest to możliwe reprezentuję Klienta w postępowaniu sądowym. Jak pokazuje praktyka, zbudowanie przemyślanej strategii procesowej na wczesnym etapie sporu oraz siła argumentacji prezentowanej na sali sądowej, to podstawa sukcesu Klienta.',
    [Specs.SprawyBankowe]:
        'W imieniu moich Klientów świadczę usługi związane z dochodzeniem roszczeń wobec instytucji finansowych, a także oceniam zasadność żądań kierowanych przez m. in. banki występujące przeciwko swoim klientom. W przypadku powierzenia mi sprawy biorę aktywny udział w procesie przedsądowym (reklamacyjnym) i sądowym. Szczególną uwagę kładę na bieżące śledzenie linii orzeczniczej, co pozwala opracować indywidualną strategię procesową dla moich Klientów.',
    [Specs.Odszkodowania]:
        'Moje wsparcie w odzyskiwaniu odszkodowań obejmuje między innymi: analizę dostarczonej dokumentacji; zgłaszanie roszczeń do towarzystw ubezpieczeniowych; sporządzanie pism – odwołania, pozwy i inne; reprezentację klienta w kontaktach z ubezpieczycielem; reprezentację klienta w trakcie rozpraw sądowych.',
    [Specs.PrawoPracy]:
        'Udzielam pracodawcom porad prawnych, przygotowuję odpowiednią dokumentację oraz reprezentuję klientów w sporach z pracownikami, które obejmuje prawo pracy. Pomagam także pracownikom w dochodzeniu praw oraz reprezentowaniu ich interesów.',
    [Specs.Umowy]:
        'Prawo umów to szeroka i wymagająca specjalistycznej wiedzy gałąź prawa. O tym czy kontrakt spełni swoją funkcję i zapewni Klientowi możliwość skutecznej realizacji jego celu, mogą zadecydować pozornie nieistotne szczegóły. Podejmując się realizacji dużego przedsięwzięcia, warto więc zadbać przede wszystkim o bezpieczeństwo prawne. Oferuję pomoc na każdym z etapów, związanych z realizacją przedmiotu kontraktu.',
    [Specs.PrawoRodzinne]:
        'Z pełnym zaangażowaniem prowadzę sprawy z zakresu prawa rodzinnego. Mam pełną świadomość, że problematyka związana np. z rozwodem lub władzą rodzicielską nad dziećmi wywołuje wśród Klientów wiele emocji. Dlatego gwarantuję moim Klientom profesjonalne świadczenie usług w przyjaznej dla nich atmosferze – poczucia bezpieczeństwa, zaufania i dyskrecji.',
    [Specs.PrawoSpadkowe]:
        'Świadczę kompleksową pomoc prawną w zakresie prawa spadkowego, w tym udzielam porad prawnych oraz reprezentuję Klientów przed sądami. Sprawy spadkowe mają szczególny charakter i do każdej sprawy podchodzę indywidualnie.',
    [Specs.PrawoKonsumenckie]:
        'Świadczę usługi prawne dla osób fizycznych oraz podmiotów prawnych, zarówno na etapie postępowania reklamacyjnego jak i postępowania sądowego. Oferuję pomoc m.in. w zakresie: sprzedaży konsumenckiej i dochodzenia roszczeń z nią związanych; zawierania umów na odległość i poza lokalem przedsiębiorstwa; analizy umów i regulaminów świadczenia usług pod kątem klauzul abuzywnych; sporządzenia regulaminów sklepów internetowych.',
    [Specs.PostepowanieEgzekucyjne]:
        'Praktyczna znajomość przepisów o egzekucji to z perspektywy wierzyciela szybkie i sprawne odzyskanie pieniędzy. Z kolei z perspektywy dłużnika gwarancja ochrony prawnej przed niekontrolowaną windykacją, w tym możliwością naruszenia praw dłużnika i osób trzecich, których dotykają skutki prowadzonej egzekucji.',
};
