import React from 'react';
import styled from 'styled-components';

interface IProps {
    open: boolean;
    shrinked: boolean;
    color: string;

    onClick(): void;
}

export const MenuButton = (props: IProps) => {
    return (
        <StyledBurger {...props}>
            <div />
            <div />
            <div />
        </StyledBurger>
    );
};

interface IStyledBurger {
    open: boolean;
    shrinked: boolean;
    color: string;
}

const StyledBurger = styled.button<IStyledBurger>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 28px;
    height: 23px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    div {
        width: 30px;
        height: 3px;
        background: ${({ open, shrinked, color }: IStyledBurger) => (open ? '#fff' : shrinked ? '#083E71' : color)};
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 5px;

        :first-child {
            transform: ${({ open }: IStyledBurger) => (open ? 'rotate(45deg)' : 'rotate(0)')};
            background: ${({ open, shrinked, color }: IStyledBurger) => (open ? '#fff' : shrinked ? '#083E71' : color)};
        }

        :nth-child(2) {
            opacity: ${({ open }: IStyledBurger) => (open ? '0' : '1')};
            transform: ${({ open }: IStyledBurger) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }: any) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
            background: ${({ open, shrinked, color }: any) => (open ? '#fff' : shrinked ? '#083E71' : color)};
        }
    }
`;
