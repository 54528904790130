import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Service, services } from './PageServices';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { Text } from '../components/Text';
import { Navigation, Autoplay, Pagination } from 'swiper';
import { Link, useNavigate } from 'react-router-dom';

interface IPageServiceOthersProps {
    activeService: Service;
}

export const PageServiceOthers = ({ activeService }: IPageServiceOthersProps) => {
    const theme = useTheme();
    const devicePhone = useMediaQuery(theme.breakpoints.down('sm'));
    const deviceTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const navigate = useNavigate();

    function renderBullet(index: number, className: any) {
        return `    
            <div class="${className} swiper-bullet-custom"></div>
        `;
    }

    const handleSlideClick = (link: string) => () => {
        navigate(link)
        window.scroll(0 ,0)
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Header>
                        POZOSTAŁE USŁUG<LastLetter>I</LastLetter>
                    </Header>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <SwiperContainer>
                    <Swiper
                        slidesPerView={1}
                        mousewheel={true}
                        speed={800}
                        modules={[Navigation, Autoplay, Pagination]}
                        loop={true}
                        pagination={
                            devicePhone
                                ? {
                                      clickable: true,
                                      renderBullet,
                                      modifierClass: 'swiper-pagination-services ',
                                  }
                                : false
                        }
                        autoplay={{
                            delay: 1500,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                        navigation={
                            devicePhone
                                ? false
                                : {
                                      nextEl: '.swiper-other-services-button.swiper-other-services-button-next',
                                      prevEl: '.swiper-other-services-button.swiper-other-services-button-prev',
                                  }
                        }
                    >
                        {services.map((service: any, key: number) => {
                            if (service.name === activeService) return null;

                            return (
                                <SwiperSlide key={key.toString()}>
                                    <SlideContainer container onClick={handleSlideClick(service.link)}>
                                        <Grid item xs={12}>
                                            <Grid container direction={devicePhone ? 'column' : 'row'}>
                                                <Image bg={service.swiperBg} />
                                                <SlideText>{service.text}</SlideText>
                                            </Grid>
                                        </Grid>
                                    </SlideContainer>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                    {devicePhone ? null : (
                        <>
                            <div className="swiper-other-services-button swiper-other-services-button-next" />
                            <div className="swiper-other-services-button swiper-other-services-button-prev" />
                        </>
                    )}
                </SwiperContainer>
            </Grid>
        </Grid>
    );
};

interface IImageProps {
    bg: string;
}

const SwiperContainer = styled.div`
    position: relative;
    width: 750px;
    margin: 0 auto;

    ${(props) => props.theme.breakpoints.down('md')} {
        width: 600px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        width: 100%;
    }
`;

const Image = styled.div<IImageProps>`
    width: 475px;
    height: 411px;
    background-image: url('${({ bg }: IImageProps) => bg}');
    background-size: cover;
    background-repeat: no-repeat;

    ${(props) => props.theme.breakpoints.down('md')} {
        width: 380px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        width: 100%;
    }
`;

const SlideText = styled(Text)`
    margin: 0;
    width: 275px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: #eff7fa;
    color: #083e71;
    text-align: left;

    ${(props) => props.theme.breakpoints.down('md')} {
        width: 220px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 20px;
        width: 100%;
        justify-content: center;
    }
`;

const SlideContainer = styled(Grid)`
    cursor: pointer;
    
    ${(props) => props.theme.breakpoints.down('sm')} {
        // flex-direction: column;
        // flex: 1;
    }

    max-width: 100%;
`;

const Header = styled.div`
    color: #083e71;
    border-bottom: 1px solid #083e71;
    text-align: center;
    // padding-bottom: 2px;
    margin-bottom: 30px;

    font-family: 'Hind', serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 5px;
`;

const LastLetter = styled.span`
    letter-spacing: 0;
`;
