import React from "react";
import { ContactTexts} from "./Contact";
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import {CopyRight} from "../components/Text";
import {SocialMediaIcon} from "./Footer";
import styled from "styled-components";

interface IProps {
    header?: boolean;
}

export const ContactSocialMedia = ({header = false}: IProps) => {

    const theme = useTheme();
    const devicePhone = useMediaQuery(theme.breakpoints.down('sm'));

    if (devicePhone) return null;

    return (
        <ContactTexts>
            {header ? (
                <ContactText bold>
                    Portale społecznościowe:
                </ContactText>
            ) : null}
            <ContactText image={<SocialMediaIconFace src="/resources/icons/icon-11-blue.svg" alt="Facebook" />}>
                <a href="https://www.facebook.com/KancelariaOlczyk">KancelariaOlczyk</a>
            </ContactText>
            <ContactText image={<SocialMediaIconInsta src="/resources/icons/insta-blue.svg" alt="Instagram - Kancelaria Olczyk" />}>
                <a href="https://www.instagram.com/kancelaria_olczyk/">kancelaria_olczyk</a>
            </ContactText>
            <ContactText image={<SocialMediaIconLinked src="/resources/icons/linked-blue.svg" alt="LinkedIn - Kancelaria Olczyk" />}>
                <a href="http://www.linkedin.com/in/kancelariaolczyk">kancelariaolczyk</a>
            </ContactText>
            <ContactText image={<SocialMediaIconTiktok src="/resources/icons/tiktok-black.svg" alt="TikTok - Kancelaria Olczyk" />}>
                <a href="https://www.tiktok.com/@kancelaria_olczyk">kancelaria_olczyk</a>
            </ContactText>
        </ContactTexts>
    )
}

function ContactText(props: any) {
    return (
        <Grid container alignItems="center">
            <ContactTextImg>
                {props.image}
            </ContactTextImg>
            <CopyRight bold={props.bold}>{props.children}</CopyRight>
        </Grid>
    );
}

const ContactTextImg = styled.div`
    width: 30px;
    height: 25px;
    // margin-right: 5px;
    // margin-bottom: 5px;
`;

export const SocialMediaIconFace = styled(SocialMediaIcon)`
    margin-right: 2px;
    width: 25px;
    height: 25px;
    margin-top: -3px;
`

export const SocialMediaIconInsta = styled(SocialMediaIcon)`
    // margin-bottom: 6px;
    // margin-left: 0px;
    margin-right: 7px;
    width: 20px;
    height: 20px;
        // margin-top: -3px;
`

export const SocialMediaIconLinked = styled(SocialMediaIcon)`
    // margin-bottom: 6px;
    // margin-left: 5px;
    margin-right: 4px;
    width: 20px;
    height: 20px;
        // margin-top: -4px;

`

export const SocialMediaIconTiktok = styled(SocialMediaIcon)`
    // margin-bottom: 6px;
    // margin-left: 1px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
        // margin-top: -4px;

    
`