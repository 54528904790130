import React from 'react';
import { Text } from '../components/Text';
import { SubPageTheme } from '../components/SubPageTheme';
import { SubPageServiceTheme } from './SubPageServiceTheme';
import {PageAboutOthers} from "./PageAboutOthers";
import {useMediaQuery, useTheme} from "@mui/material";

const texts = [
    'Jestem absolwentem Wydziału Prawa i Administracji Uniwersytetu Łódzkiego oraz stypendystą rektora Uniwersytetu Łódzkiego.',
    'Ukończyłem aplikację radcowską przy Okręgowej Izbie Radców Prawnych w Łodzi, a po złożeniu egzaminu zawodowego zostałem tam wpisany na listę radców prawnych.',
    'Swoje doświadczenie zawodowe nabyłem w pracy m. in. dla kancelarii obsługującej jeden z największych podmiotów rynku finansowego w Polsce oraz w jednej z największych w województwie łódzkim kancelarii windykacyjnych. Mam wieloletnie doświadczenie w zastępstwie procesowym przed sądami powszechnymi na terenie całego kraju.',
    'Prywatnie jestem medalistą Mistrzostw Polski i Europy, instruktorem oraz sędzią okręgowym Polskiego Związku Karate Tradycyjnego. Uważam, że osiągnięcia w sporcie miały w moim życiu bezpośrednie przełożenie na pracę zawodową, w której cechuje mnie rzetelność, profesjonalizm i efektywność.',
];

export const PageAbout = () => {
    const theme = useTheme();
    const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    function getHeaderBg() {
        return mediaTablet
            ? '/resources/images/Drugi_slajd_radca_prawny_Michal.jpg'
            : '/resources/images/MENU_GLOWNA_FOTA_o_kancelarii.jpg'
    }

    return (
        <SubPageTheme headerBg={getHeaderBg()}>
            <SubPageServiceTheme header="O Kancelarii">
                {texts.map((text) => (
                    <Text key={text}>{text}</Text>
                ))}

                <div style={{marginTop: 70}}>
                    <PageAboutOthers />
                </div>
            </SubPageServiceTheme>
        </SubPageTheme>
    );
};
