import React from 'react';
import {Grid, useMediaQuery, useTheme} from '@mui/material';
import styled from 'styled-components';
import {Paragraph} from '../components/Paragraph/Paragraph';
import {CopyRight} from '../components/Text';
import {ContactGoogleMap} from "./ContactGoogleMap";
import {ContactSocialMedia} from "./ContactSocailMedia";

interface IContactContentProps {
    headerVisible?: boolean;
    page?: 'main' | 'contact'
}

export const Contact = ({headerVisible = true, page = 'contact'}: IContactContentProps) => {
    const theme = useTheme();
    const deviceTabletAndPhone = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container container>
            {headerVisible ? (
                <Header item xs={12} md={12} textAlign="center">
                    <Paragraph size="l" bold margin="s">
                        Masz wątpliwość, czy Twoja sprawa mieści się w moim katalogu?
                    </Paragraph>
                    <Paragraph size="m">Skontaktuj się ze mną!</Paragraph>
                </Header>
            ) : null}
            <Grid item md={12}>
                <Grid container alignItems="center" justifyContent="center">
                    <MapContainer>
                        <ContactGoogleMap zoom={15} />
                    </MapContainer>
                    <ContactContainer>
                        <ContactWrapper container direction={deviceTabletAndPhone ? 'row' : 'column'}>
                            <ContactTexts>
                                <ContactText bold>Kontakt:</ContactText>
                                <ContactText image={'/resources/icons/icon-12.svg'}>
                                    <a href="tel:+48661533865">661 533 865</a>
                                </ContactText>
                                <ContactText image={'/resources/icons/icon-13.svg'}>
                                    <a href="mailto:kontakt@kancelariaolczyk.pl">kontakt@kancelariaolczyk.pl</a>
                                </ContactText>
                            </ContactTexts>
                            <ContactTexts className="landing-page-contact__address">
                                <ContactText bold image={'/resources/icons/icon-14.svg'}>
                                    Adres:
                                </ContactText>
                                <ContactText>Kancelaria Radcy Prawnego</ContactText>
                                <ContactText>Michał Olczyk</ContactText>
                                <ContactText>ul. Brzezińska 14</ContactText>
                                <ContactText>95-040 Koluszki</ContactText>
                                <ContactText>
                                    <a target={'_blank'}
                                       href={'https://www.google.pl/maps/place/Kancelaria+Radcy+Prawnego+Micha%C5%82+Olczyk/@51.7454179,19.8127318,17z/data=!3m1!4b1!4m6!3m5!1s0x471bd5391c507c4d:0x7dbe2925d4bd768c!8m2!3d51.7454179!4d19.8153067!16s%2Fg%2F11rzpzdn9q?entry=ttu'}>
                                        Wyznacz trasę
                                    </a>
                                </ContactText>
                            </ContactTexts>
                            <ContactSocialMedia header />
                        </ContactWrapper>
                    </ContactContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

export function ContactText(props: any) {
    return (
        <Grid container alignItems="center">
            <ContactTextImg src={props.image ? props.image : ' '}/>
            <CopyRight bold={props.bold}>{props.children}</CopyRight>
        </Grid>
    );
}

const Container = styled(Grid)`
    padding-top: 20px;

    ${(props) => props.theme.breakpoints.between('md', 'lg')} {
        padding-top: 3vh;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        // padding: 20px 20px 0;
    }
`;

export const ContactTexts = styled.div`
    padding-left: 20px;
    padding-top: 10px;

    ${(props) => props.theme.breakpoints.down('md')} {
        &:first-child {
            padding-right: 20px;
        }

        &:last-child {
            margin-top: -5px;
            padding-right: 20px;
        }
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        display: block;
        width: 100%;
        padding: 20px 20px;
    }
`;

const ContactTextImg = styled.img`
    width: 25px;
    margin-right: 5px;
`;

const Header = styled(Grid)`
    padding-bottom: 50px;
    
    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 0 30px 50px;
    }
`;

const ContactWrapper = styled(Grid)`
    margin-bottom: 40px;

    ${(props) => props.theme.breakpoints.down('md')} {
        justify-content: center;
        // align-items: center;
    }
    
    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 0 20px;
    }
`;

const ContactContainer = styled.div`
    // flex: 1;

    ${(props) => props.theme.breakpoints.down('md')} {
        // padding: 0 100px;
        order: 1;
    }
`;


const MapContainer = styled.div`
    width: 500px;
    height: 350px;

    ${(props) => props.theme.breakpoints.down('md')} {
        padding: 0 0 40px;
        // padding-top: 40px;
        order: 2;
        width: 600px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        width: 100%;
        height: 80vh;
    }
    
    ${(props) => props.theme.breakpoints.up('xl')} {
        max-width: 50vh;
        margin-right: 30px;
    }

`;
