export enum Links {
    About = '/o-kancelarii',
    ZakresUslug = '/zakres-uslug',
    Specializacje = '/specjalizacje',
    Kontakt = '/kontakt',
    UdzielaniePoradPrawnych = '/zakres-uslug/udzielanie-porad-prawnych',
    SporzadzanieOpiniPrawnych = '/zakres-uslug/sporzadzanie-opinii-prawnych',
    TworzenieIOpiniowanieUmow = '/zakres-uslug/tworzenie-i-opiniowanie-umow',
    ZastepstwoProcesowe = '/zakres-uslug/zastepstwo-procesowe',
}
