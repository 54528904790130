import React from 'react';
import styled from 'styled-components';
import { Paragraph } from './Paragraph/Paragraph';

export const ListItem = styled(Paragraph)`
    &::before {
        content: '';
        width: 6px;
        height: 6px;
        background: #083e71;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
        transform: translateY(-3px);
    }
`;
