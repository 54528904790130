import React from 'react';
import styled from 'styled-components';

type Size = 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
type Display = 'inline' | 'block' | 'inline-block';
type Font = 'primary' | 'secondary';
type Color = 'primary' | 'secondary' | 'background';
type Weight = '400' | '500' | '600' | '700' | '800' | '900';

export interface IParagraphProps {
    size?: Size;
    padding?: Size;
    margin?: Size;
    bold?: boolean;
    weight?: Weight;
    display?: Display;
    fontFamily?: Font;
    color?: Color;
    spacing?: Size;
    visible?: boolean;
}

function getFontFamily(font: Font = 'primary') {
    if (font === 'primary') return 'Playfair Display, serif';
    if (font === 'secondary') return 'Hind, serif';
}

function getFontSize(size: Size = 'm') {
    if (size === 'none') return '0';
    if (size === 'xxs') return '9px';
    if (size === 'xs') return '11px';
    if (size === 's') return '14px';
    if (size === 'm') return '17px';
    if (size === 'l') return '20px';
    if (size === 'xl') return '24px';
}

function getFontWeight(bold?: boolean) {
    return bold ? '700' : '500';
}

function getPadding(padding: Size = 'xs') {
    if (padding === 'none') return '0';
    if (padding === 'xxs') return '2px 0';
    if (padding === 'xs') return '5px 0';
    if (padding === 's') return '10px 0';
    if (padding === 'm') return '20px 0';
    if (padding === 'l') return '25px 0';
    if (padding === 'xl') return '30px 0';
}

function getColor(color: Color = 'primary') {
    if (color === 'primary') return '#083E71';
    if (color === 'secondary') return '#6c7f96';
    if (color === 'background') return '#fff';
}

function getSpacing(size: Size = 'none') {
    if (size === 'none') return 'inherit';
    if (size === 'xxs') return '1px';
    if (size === 'xs') return '2px';
    if (size === 's') return '3px';
    if (size === 'm') return '4px';
    if (size === 'l') return '5px';
    if (size === 'xl') return '7px';
}

export const Paragraph = styled.p<IParagraphProps>`
    font-family: ${({ fontFamily }: IParagraphProps) => getFontFamily(fontFamily)};
    font-size: ${(props: IParagraphProps) => getFontSize(props.size)};
    font-weight: ${({ bold, weight }: IParagraphProps) => (bold ? getFontWeight(bold) : weight)};
    color: ${({ color }: IParagraphProps) => getColor(color)};
    display: ${({ display = 'block' }: IParagraphProps) => display};
    padding: ${({ padding }) => getPadding(padding)};
    margin: ${({ margin }) => getPadding(margin)};
    letter-spacing: ${({ spacing }) => getSpacing(spacing)};

    opacity: ${({ visible = true }: IParagraphProps) => (visible ? '1' : '0')};
    transition: opacity 0.3s ease-in;
`;
