import React from 'react';
import { SubPageTheme } from '../components/SubPageTheme';
import { Contact } from './Contact';
import styled from 'styled-components';
import { Grid } from '@mui/material';

export const PageContact = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_Kontakt.jpg">
            <Information container justifyContent="center">
                <Contact />
            </Information>
        </SubPageTheme>
    );
};

const Information = styled(Grid)`
    margin-bottom: -20px;
`;
