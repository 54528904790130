import React from 'react';
import { imagesMap, specializationsDesktop, specializationsTablet, Specs, texts } from './constants';
import { SpecializationsMediaTheme } from './SpecializationsMediaTheme';
import styled from 'styled-components';
import { Information } from '../../components/Text';
import AnimateHeight from 'react-animate-height';

interface IProps {
    specializations: Specs[][];
}

export const SpecializationsMobile: React.FC<IProps> = ({ specializations }) => {
    const [activeSpec, setActiveSpec] = React.useState('');

    const handleSpecToggle = (spec: Specs) => () => {
        if (activeSpec === spec) {
            setActiveSpec('');
        } else {
            setActiveSpec(spec);
        }
    };

    return (
        <SpecializationsMediaTheme>
            <Container>
                {specializations.map((group) => (
                    <>
                        <Specializations>
                            {group.map((spec) => (
                                <Specialization>
                                    <Image
                                        active={!activeSpec || activeSpec === spec}
                                        onClick={handleSpecToggle(spec)}
                                        src={imagesMap[spec]}
                                    />
                                </Specialization>
                            ))}
                        </Specializations>
                        <div>
                            {group.map((spec) => (
                                <AnimateHeight
                                    duration={300}
                                    height={activeSpec === spec ? 'auto' : '0'} // see props documentation below
                                >
                                    <Description>
                                        <Information>{texts[spec]}</Information>
                                    </Description>
                                </AnimateHeight>
                            ))}
                        </div>
                    </>
                ))}
            </Container>
        </SpecializationsMediaTheme>
    );
};

const Container = styled.div`
    // margin-bottom: 50px;
`;

const Specialization = styled.div``;

const Image = styled.img<{ active: boolean }>`
    width: 150px;
    height: auto;
    opacity: ${(props) => (props.active ? '1' : '.7')};
    transition: all 0.3s ease-in;

    ${(props) => props.theme.breakpoints.down('sm')} {
        width: 140px;
    }
`;

const Description = styled.div`
    padding: 15px 0;
    text-align: center;
`;

const Specializations = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    ${(props) => props.theme.breakpoints.down('sm')} {
        justify-content: space-around;
    }

    ${(props) => props.theme.breakpoints.between('sm', 'lg')} {
        justify-content: center;
        
        ${Specialization} {
            margin: auto 25px;
        }
    }
`;
